<template>
    <div class="btn-group" :class="{'d-none': !hasConfigurations}" role="group" @click="stopEvent">
        <!-- <button type="button" --> 
            <!-- class="btn btn-primary btn-sm" --> 
            <!-- :disabled="currentLevel <= 0" -->
            <!-- style="border-radius: 10px 00px 0px 10px;" -->
            <!-- :title="$t('Go up a level')" -->
            <!-- @click="expandToLevel(currentLevel-1)"> -->
            <!-- <i class="bi bi-chevron-double-up"></i> -->
        <!-- </button> -->
        <GroupByLevelsDropdown v-slot="scope" :dataObject="dataObject">
            <button type="button" 
                :ref="scope.target"
                class="btn btn-primary btn-sm"
                :title="$t('Set to level')"
                @click="scope.open()">
                {{currentLevel+1}}
            </button>
        </GroupByLevelsDropdown>
        <!-- <button type="button" --> 
            <!-- class="btn btn-primary btn-sm" -->
            <!-- :disabled="currentLevel >= deepestLevel" -->
            <!-- style="border-radius: 0px 10px 10px 0px;" -->
            <!-- :title="$t('Go down a level')" -->
            <!-- @click="expandToLevel(currentLevel+1)"> -->
            <!-- <i class="bi bi-chevron-double-down"></i> -->
        <!-- </button> -->
    </div>
</template>

<script lang="ts">
export interface IProps {
    dataObject: DataObject
};
</script>

<script setup lang="ts">
import type { DataObject }  from 'o365-dataobject';

import GroupByLevelsDropdown from './components.LevelSelectorDropdown.vue';

import { computed } from 'vue';

const props = defineProps<IProps>();

const currentLevel = computed(() => props.dataObject.nodeData?.currentLevel ?? 0);
const deepestLevel = computed(() => props.dataObject.nodeData?.deepestLevel ?? 0);
const hasConfigurations = computed(() => props.dataObject.nodeData?.configurations.length > 0 ?? false);

function expandToLevel(level: number) {
    props.dataObject.nodeData.expandToLevel(level);
}

function stopEvent(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
}

</script>